import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";

const Header = () => {
    const [navMenuActive, setNavMenuActive] = useState(false);
  const [servicesDropdownActive, setServicesDropdownActive] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleNavItemClick = (event) => {
      const target = event.currentTarget;
      const isDropdownToggle = target.classList.contains("dropdown-toggle");

      if (isDropdownToggle) {
        event.preventDefault(); // Prevent default behavior for dropdown toggles

        // Toggle dropdown logic here if needed
        setServicesDropdownActive(!servicesDropdownActive);
      } else {
        const parentDropdown = target.closest(".dropdown-menu");

        if (parentDropdown) {
          // Toggle parent dropdown logic here if needed
        } else {
          setNavMenuActive(false); // Hide mobile navigation menu
        }
      }
    };

    const navLinks = document.querySelectorAll(".navbar-collapse a");
    navLinks.forEach((link) => {
      link.addEventListener("click", handleNavItemClick);
    });

    return () => {
      navLinks.forEach((link) => {
        link.removeEventListener("click", handleNavItemClick);
      });
    };
  }, [servicesDropdownActive]);

  const handleHamburgerClick = () => {
    setNavMenuActive(!navMenuActive);
  };

  return (
    <header className="container">
    <nav className={`navbar navbar-expand-lg navbar-light TopnavBar-Section p-2`}>
      <div id="progress-barss"></div>
      <div className="container">
        <NavLink className="navbar-brand logo-section" to="/landing">
          <img
            className="main-logo"
            src="../images/navbar/logo.png"
            loading="eager"
            alt="cerohero-logo"
          />
        </NavLink>
        <form className="form-inline d-block d-lg-none">
          <NavLink
            className="btn login-txt"
            type="button"
            to={"https://www.thedm.cloud/register"}
            target="_blank"
          >
            Sign up
          </NavLink>
          <NavLink
            className="btn login-txt"
            type="button"
            to={"https://www.thedm.cloud/login"}
            target="_blank"
          >
            Login
          </NavLink>
        </form>

        <div className={`hamburger-edit ${navMenuActive ? "active" : ""}`} onClick={handleHamburgerClick}>
          <span className="bar" />
          <span className="bar" />
          <span className="bar" />
        </div>
        <div className={`navbar-collapse navbarTop-colapse ${navMenuActive ? "active" : ""}`}>
          <ul className="navbar-nav mr-auto navbarTopUl-edit p-2">
            <li className={`nav-item ${location.pathname === '/landing' ? 'active' : ''}`}>
              <NavLink className="nav-link navlink-mainlist" to="/landing">
                Home
              </NavLink>
            </li>
            <li className={`nav-item ${location.pathname === '/aboutus' ? 'active' : ''}`}>
              <NavLink className="nav-link navlink-mainlist" to="/aboutus">
                About Us
              </NavLink>
            </li>
            <li className={`nav-item ${location.pathname === '/ourteam' ? 'active' : ''}`}>
              <NavLink className="nav-link navlink-mainlist" to="/ourteam">
                Our Team
              </NavLink>
            </li>
            <li className={`nav-item ${location.pathname === '/contactus' ? 'active' : ''}`}>
              <NavLink className="nav-link navlink-mainlist" to="/contactus">
                Contact Us
              </NavLink>
            </li>
          </ul>
          <form className="form-inline d-none d-lg-block">
            <NavLink
              className="btn login-txt"
              type="button"
              to={"https://www.thedm.cloud/register"}
              target="_blank"
              
            >
              Sign up
            </NavLink>
            <NavLink
              className="btn login-txt"
              type="button"
              to={"https://www.thedm.cloud/login"}
              target="_blank"
            >
              Login
            </NavLink>
          </form>
        </div>
      </div>
    </nav>
  </header>
  );
};

export default Header;

import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import 'swiper/css/zoom';

import { FreeMode, Navigation, Thumbs, Autoplay, Zoom } from 'swiper/modules';


const Landing = () => {
  // const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [activeSlideId, setActiveSlideId] = useState(null);


  const [thumbsSwiper, setThumbsSwiper] = useState(null);



  const handleSlideChange = (swiper) => {
    // Get the active slide's id using Swiper instance
    const activeSlideId = swiper.slides[swiper.activeIndex].id;
    setActiveSlideId(activeSlideId);
  };

  const handleSlideChangeTransitionEnd = (swiper) => {
    // Get the ID of the centered slide using Swiper instance
    const centeredSlideId = swiper.slides[swiper.activeIndex].id;
    console.log('Centered Slide ID:', centeredSlideId);
  };

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const getContentBasedOnId = (id) => {
    // Map between slide IDs and corresponding content
    const contentMap = {
      '1': {
        message: "As someone new to cryptocurrency mining, MyDMFarms has made the process incredibly user-friendly. Their support and educational resources are top-notch. I'm thrilled to be part of an eco-conscious mining community that's making a real difference.",
        name: 'Mark J',
      },
      '2': {
        message: "MyDMFarms has been a game-changer in my crypto mining journey. Their dedication to sustainability and innovation sets them apart. I'm not only maximizing my returns, but I'm also contributing to a greener, more ethical future. MyDMFarms is where profits meet purpose.",
        name: 'Sarah T',
      },
      '3': {
        message: "MyDMFarms has redefined my expectations of crypto mining. It's not just about profits; it's about shaping a better future. Their focus on sustainability and technological advancements is remarkable. This is where crypto mining meets a higher purpose.",
        name: 'Michael S',
      },
      '4': {
        message: "I've been a part of the crypto mining world for a while, and MyDMFarms is a breath of fresh air. Their commitment to transparency and ethical practices is commendable. This platform is revolutionizing mining and making it more responsible and accessible.",
        name: 'Robert H',
      },
      '5': {
        message: "MyDMFarms not only provides an exceptional mining experience but also a sense of pride. Knowing that my mining activities contribute to a cleaner environment and support social initiatives makes it truly rewarding. I'm excited to be part of this forward-thinking community.",
        name: 'Emily L',
      },
    };

    return contentMap[id] || {}; // Return an empty object if the ID is not found
  };



  return (
    <div className='landingpage-content'>
      <div className='landing-top-content'>
        <div className='joinmoment-content'>
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-md-6">
                <div className="left-transfer">
                  <h3 class="title-data">
                    Revolutionize Your Mining Journey with the World's Leading <br />
                    <span>Cloud MINING <img src="../images/landing/bitcoin-icon.svg" alt="no-data" /> </span>
                    <br />
                    Learning Experience
                  </h3>
                  <p className="subtitle-content">
                    At MyDMFarms, we're redefining the world of crypto mining, making it accessible, rewarding, and educational. That's why we've created MyDMFarms, a platform designed to make your mining journey a breeze.
                  </p>

                </div>
              </div>
              <div className="col-md-6">
                <div className="right-transfer">
                  <div className="image-content">
                    <img className='image-data' src="../images/landing/right-image.svg" alt='no-data' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='innvation-content'>
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-md-6">
              <div className="innvation-left">
                <div className="image-content">
                  <img className='image-data' src="../images/landing/innovation-left.svg" alt='no-data' />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="innvation-right">
                <h3 className="title-data">
                  Embracing Innovation <br /> and   <span>Sustainability</span>
                </h3>
                <img className='line-images' src="../images/landing/line-image.png" alt='no-data' />
                <p className="subtitle-content">
                  Join us on a transformative journey where we not only embrace innovation but also champion sustainability. At the MyDMFarms Learning Experience, we believe that mining can be both cutting-edge and eco-conscious. It's a place where you'll learn to mine smarter while making a positive impact on the planet. Discover the future of mining, today.                </p>

              </div>
            </div>

          </div>
        </div>
      </div>
      <div className='whychoose-dailymines'>
        <div className="container">
          <div className="whychoose-top">
            <h3 className="title-data">
              Why Choose <span>MyDMFarms?</span>
            </h3>
            <p className="subtitle-content">
              MyDMFarms is your opportunity to be part of this transformative movement. We invite you to join us on a journey towards a mining industry that is not only eco-friendly but also socially responsible. It's a place where innovation thrives, sustainability takes precedence, and transparency prevails.            </p>
            <img className='line-images' src="../images/landing/line-image.png" alt='no-data' />


          </div>
          <div className="row justify-content-center">
            <div className='col-md-2'>
              <div className="card dailymines-list " >
                <div className='dailyimage-top'>
                  <img src="../images/landing/whychoose-1.png" className="card-img-top dailyimage-inside" alt="..." />
                </div>
                <div className="card-body dailymines-body">
                  <h5 className="card-title dailymines-title">Expert Guidance</h5>
                  <p className="card-text dailymines-text">
                    MyDMFarms Learning Expert is led by a team of experienced professionals.
                  </p>

                </div>
              </div>


            </div>
            <div className='col-md-2'>
              <div className="card dailymines-list " >
                <div className='dailyimage-top'>
                  <img src="../images/landing/whychoose-2.png" className="card-img-top dailyimage-inside" alt="..." />
                </div>
                <div className="card-body dailymines-body">
                  <h5 className="card-title dailymines-title">Education</h5>
                  <p className="card-text dailymines-text">
                    Gain valuable knowledge through our comprehensive learning resources.                  </p>

                </div>
              </div>


            </div>
            <div className='col-md-2'>
              <div className="card dailymines-list " >
                <div className='dailyimage-top'>
                  <img src="../images/landing/whychoose-3.png" className="card-img-top dailyimage-inside" alt="..." />
                </div>
                <div className="card-body dailymines-body">
                  <h5 className="card-title dailymines-title">Cutting-Edge Rigs</h5>
                  <p className="card-text dailymines-text">
                    Choose from a wide range of powerful and efficient mining rigs.                  </p>

                </div>
              </div>


            </div>
            <div className='col-md-2'>
              <div className="card dailymines-list " >
                <div className='dailyimage-top'>
                  <img src="../images/landing/whychoose-4.png" className="card-img-top dailyimage-inside" alt="..." />
                </div>
                <div className="card-body dailymines-body">
                  <h5 className="card-title dailymines-title">Community</h5>
                  <p className="card-text dailymines-text">
                    Connect with a network of successful miners and exchange insights.                  </p>

                </div>
              </div>


            </div>
            <div className='col-md-2'>
              <div className="card dailymines-list " >
                <div className='dailyimage-top'>
                  <img src="../images/landing/whychoose-5.png" className="card-img-top dailyimage-inside" alt="..." />
                </div>
                <div className="card-body dailymines-body">
                  <h5 className="card-title dailymines-title">Support</h5>
                  <p className="card-text dailymines-text">
                    Our team of experts is here to assist you every step of the way.                  </p>

                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
      <div className='crypto-wealth-content'>
        <div className='container'>
          <div className='crypto-wealth-top'>
            <p className='cryptowealth-title'>Welcome to MyDMFarms - <span>Your Path to Crypto Wealth!</span> </p>
            <p className='cryptowealth-text'>
              "MyDMFarms: Shaping Tomorrow's Mining Industry Today”.
            </p>
            <p className='cryptowealth-text1'> Are you ready to embark on a journey towards financial freedom through cryptocurrency mining? MyDMFarms is here to guide you every step of the way. We offer you a seamless path to crypto wealth, starting with a simple registration process.</p>

          </div>
          <div className='row justify-content-center align-items-center'>
            <div className='col-md-6'>
              <div className='left-content'>
                <img className='image-content' src='../images/landing/crypto-left.svg' alt='no-data' />

              </div>

            </div>
            <div className='col-md-6'>
              <div className='right-content'>
                <h3 className='choosemine-title'>Register and Choose Your Miner</h3>
                <p className='choosemine-text'>Getting started with MyDMFarms is as easy as 1-2-3. Simply register an account with us to unlock a world of opportunities. Once you're signed up, you can choose your miner from our wide selection of cutting-edge rigs.</p>
                <h3 className='choosemine-title'>Start Learning and Earning</h3>
                <p className='choosemine-text'>At MyDMFarms, we believe that knowledge is power. That's why we provide you with comprehensive learning resources to expand your understanding of crypto mining.</p>
                <h3 className='choosemine-title'>Join a Community of Success</h3>
                <p className='choosemine-text'>By joining MyDMFarms, you become part of a vibrant community of like-minded individuals striving for crypto wealth. Connect with fellow miners, share experiences, and learn from each other's successes. Our community is a supportive network where you can exchange ideas, strategies, and tips to enhance your mining journey.</p>

              </div>
            </div>

          </div>

        </div>

      </div>
      <div className='security-content'>
        <div className='container'>
          <div className='testimonials-content'>
            <p className='testimonial-title'>what our client say about <br />
              <span>MyDMFarms</span></p>
            <img className='line-images' src="../images/landing/line-image.png" alt='no-data' />
            <div className='row justify-content-center'>
              <div className='col-md-4'>
                <Swiper
                  onSwiper={setThumbsSwiper}
                  onSlideChange={handleSlideChange}
                  onSlideChangeTransitionEnd={handleSlideChangeTransitionEnd}
                  loop={true}
                  spaceBetween={0}
                  slidesPerView={3}
                  centeredSlides={true}
                  freeMode={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Navigation, Thumbs, Autoplay, Zoom]}
                  className="mySwiper"
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                  }}
                  zoom={{ toggle: true, maxRatio: 3 }}
                >
                  <SwiperSlide id='1'>
                    <div className='image-content'>
                      <img className='profile-image' src="../images/landing/img-1.png" alt='no-data' />

                    </div>
                  </SwiperSlide>
                  <SwiperSlide id='2'>
                    <div className='image-content'>
                      <img className='profile-image' src="../images/landing/img-2.png" alt='no-data' />

                    </div>
                  </SwiperSlide>
                  <SwiperSlide id='3'>
                    <div className='image-content'>
                      <img className='profile-image' src="../images/landing/img-3.png" alt='no-data' />
      

                    </div>
                  </SwiperSlide>
                  <SwiperSlide id='4'>
                    <div className='image-content'>
                      <img className='profile-image' src="../images/landing/img-4.png" alt='no-data' />
       

                    </div>
                  </SwiperSlide>

                  <SwiperSlide id='5'>
                    <div className='image-content'>
                      <img className='profile-image' src="../images/landing/img-5.png" alt='no-data' />


                    </div>
                  </SwiperSlide>

                </Swiper>
              </div>
            </div>

            <div className='row justify-content-center'>
              <div className='col-md-10'>
                <Swiper

                  loop={true}
                  spaceBetween={50}
                  navigation={false}
                  slidesPerView={1}
                  thumbs={{ swiper: thumbsSwiper }}
                  modules={[FreeMode, Thumbs, Autoplay]}
                  centeredSlides={true}
                  className="mySwiper2"
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                  }}
                >
                  <SwiperSlide>
                    <div className='testimnial-message'>
                      <p className='message-text'>{getContentBasedOnId(activeSlideId).message}</p>
                      <p className='message-text1'>{getContentBasedOnId(activeSlideId).name}</p>
                    </div>
                  </SwiperSlide>

                </Swiper>
              </div>
            </div>


          </div>
          <div className='security-inside'>
            <h3 className='security-title'>SECURITY</h3>
            <img className='line-images' src="../images/landing/line-image.png" alt='no-data' />
            <div className='row justify-content-center'>
              <div className='col-md-3'>
                <div className='security-image'>
                  <img className='image-data' src='../images/landing/security-1.png' alt='no-data' />

                </div>
              </div>
              <div className='col-md-3'>
                <div className='security-image'>
                  <img className='image-data' src='../images/landing/security-2.png' alt='no-data' />

                </div>
              </div>
              <div className='col-md-3'>
                <div className='security-image'>
                  <img className='image-data' src='../images/landing/security-3.png' alt='no-data' />

                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Landing
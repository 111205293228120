import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'

const About = () => {
  useEffect(() => {
    window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
    });
}, []);
  return (
    <>
      <div className='aboutus-main'>
        <div className='aboutus-content'>
          <div className='inside-data'>
            <h3 className='aboutus-title'>ABOUT US</h3>
            <p className='aboutus-subtitle'><NavLink className="navlinksdata" to={"/landing"}>HOME </NavLink>/ ABOUT</p>
          </div>

        </div>
        <div className='welcome-dailymines'>
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-md-6'>
                <div className='welcomedaily-left'>
                  <h3 className='welcomeleft-title'>ABOUT US</h3>
                  <p className='welcomeleft-subtext'>Welcome to <span>MyDMFarms!</span></p>
                <img className='line-images' src="../images/landing/line-image.png" alt='no-data' />

                  <p className='welcomeleft-para'>We are a trusted cloud mining service that aims to revolutionize the world of mining. Our vision is to transform mining into a clear concept by breaking free from conventional practices and embracing innovation and sustainability.</p>
                  <div className="d-flex welcomeleft-media">
                    <div className="flex-shrink-0">
                      <img className='welcomeleft-mediaimage' src="../images/aboutus/mission-icon.svg" alt="no-data" />
                    </div>
                    <div className="flex-grow-1  ms-3">
                      <h3 className='leftmedial-title'>Mission</h3>
                      <p className='leftmedial-text'>At MyDMFarms, our mission is to revolutionize the mining industry through sustainable, ethical, and innovative practices while making cryptocurrency mining accessible to all.</p>

                    </div>
                  </div>
                  <div className="d-flex welcomeleft-media">
                    <div className="flex-shrink-0">
                      <img className='welcomeleft-mediaimage' src="../images/aboutus/vission-icon.svg" alt="no-data" />
                    </div>
                    <div className="flex-grow-1  ms-3">
                      <h3 className='leftmedial-title'>Vision</h3>
                      <p className='leftmedial-text'>Our vision is to lead global eco-conscious mining, fostering innovation, sustainability, and transparency, while driving positive social and environmental impact.</p>

                    </div>
                  </div>

                </div>
              </div>
              <div className='col-md-6'>
                <div className='welcomedaily-right'>
                  <img className='right-image' src='../images/aboutus/misssion-right.svg' alt='no-data' />
                </div>
              </div>

            </div>
          </div>

        </div>
        <div className='smartchoice-content'>
          <div className='container'>
            <div className='smartchoice-data'>
              <div className='choice-top'>
                <h3 className='smartchoice-title'>Why <span>MyDMFarms</span> is Your Smart Choice?</h3>
                <img className='line-images' src="../images/landing/line-image.png" alt='no-data' />

                <p className='smartchoice-text'>
                  Choose MyDMFarms for comprehensive education, user-friendly design, expert guidance, personalized learning, technological innovation, transparent pricing, dedicated support, and a vibrant mining community. Start your cloud mining journey with us to unlock the full potential of cryptocurrency mining.
                </p>
              </div>
              <div className='row'>
                <div className='col-md-4'>
                  <div className='smartchoice-inside'>
                    <div className="card smartchoice-card" >
                      <img src="../images/aboutus/choosemines-1.svg" className="card-img-top smartchoice-image" alt="..." />
                      <div className="card-body smartchoice-body">
                        <h5 className="card-title smartchoice-title">Comprehensive Education</h5>
                        <p className="card-text smartchoice-text">
                          MyDMFarms provides a structured learning experience for cloud mining enthusiasts. Access a wide range of educational resources, interactive modules, and expert insights to gain the knowledge and skills for success in mining.
                        </p>

                      </div>
                    </div>


                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='smartchoice-inside'>
                    <div className="card smartchoice-card" >
                      <img src="../images/aboutus/choosemines-2.svg" className="card-img-top smartchoice-image" alt="..." />
                      <div className="card-body smartchoice-body">
                        <h5 className="card-title smartchoice-title">Experienced Professionals</h5>
                        <p className="card-text smartchoice-text">
                        Our team of experts ensures that our educational resources remain up-to-date, offering valuable insights into the ever-evolving world of cryptocurrency mining.                        </p>

                      </div>
                    </div>


                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='smartchoice-inside'>
                    <div className="card smartchoice-card" >
                      <img src="../images/aboutus/choosemines-3.svg" className="card-img-top smartchoice-image" alt="..." />
                      <div className="card-body smartchoice-body">
                        <h5 className="card-title smartchoice-title">Personalized Learning Paths</h5>
                        <p className="card-text smartchoice-text">
                        Tailor your learning journey to meet your unique goals, progressing at your own pace and focusing on areas that interest you the most.                        </p>

                      </div>
                    </div>


                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='smartchoice-inside'>
                    <div className="card smartchoice-card" >
                      <img src="../images/aboutus/choosemines-4.svg" className="card-img-top smartchoice-image" alt="..." />
                      <div className="card-body smartchoice-body">
                        <h5 className="card-title smartchoice-title">Transparent Pricing</h5>
                        <p className="card-text smartchoice-text">
                        We believe in transparent and competitive pricing, offering cost-effective packages that deliver value for your investment.                        </p>

                      </div>
                    </div>


                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='smartchoice-inside'>
                    <div className="card smartchoice-card" >
                      <img src="../images/aboutus/choosemines-5.svg" className="card-img-top smartchoice-image" alt="..." />
                      <div className="card-body smartchoice-body">
                        <h5 className="card-title smartchoice-title">Dedicated Support</h5>
                        <p className="card-text smartchoice-text">
                        Our committed support team is available to assist you with timely and comprehensive guidance throughout your learning experience.                        </p>

                      </div>
                    </div>


                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='smartchoice-inside'>
                    <div className="card smartchoice-card" >
                      <img src="../images/aboutus/choosemines-6.svg" className="card-img-top smartchoice-image" alt="..." />
                      <div className="card-body smartchoice-body">
                        <h5 className="card-title smartchoice-title">Community Engagement</h5>
                        <p className="card-text smartchoice-text">
                        Join a supportive community of miners, network, collaborate, and learn from fellow enthusiasts through our community forum.                        </p>

                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default About
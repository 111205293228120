import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {


    return (
        <>
            <div className="footer-main">

                <div className="footermain-section">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-4">
                                <div className="footer-sec">
                                    <img
                                        className="footer-im"
                                        src="../images/footer/footer-logo.png"
                                        alt="Chakravuyha-Logo"
                                    />
                                    <p className="footer-text">
                                    At MyDMFarms, we're redefining the world of crypto mining, making it accessible, rewarding, and educational.                                     </p>
                                    <ul className="Privacyterms2">
{/*                                         
                                        <li className="privacygap">
                                            <NavLink className="socila-images"
                                                to={"https://twitter.com/Daily_Mines"}
                                                target="_blank"
                                            >
                                                 <i class="fab fa-twitter social-icon"></i>
                                               
                                            </NavLink>
                                        </li> */}
                                        <li className="privacygap">
                                            <NavLink className="socila-images socila-images1"
                                                to={"https://www.facebook.com/OfficialDailyMines"}
                                                target="_blank"
                                            >
                                             <i class="fab fa-facebook-f social-icon"></i>
                                            </NavLink>
                                        </li>
                                        <li className="privacygap">
                                            <NavLink
                                            className="socila-images socila-images2"
                                                to={"https://www.instagram.com/daily_mines/"}
                                                target="_blank"
                                            >
                                                <i class="fab fa-instagram social-icon"></i>
                                            </NavLink>
                                        </li>

                                      
                                        <li className="privacygap">
                                            <NavLink
                                            className="socila-images socila-images3"
                                                to={"https://www.youtube.com/@Dailyminescommunity/videos"}
                                                target="_blank"
                                            >
                                              <i class="fa-brands fa-youtube social-icon"></i>
                                            </NavLink>
                                        </li>
                                        <li className="privacygap">
                                            <NavLink
                                            className="socila-images socila-images4"
                                                to={"https://t.me/dailymines"}
                                                target="_blank"
                                            >
                                              <i class="fa-brands fa-telegram social-icon"></i>
                                            </NavLink>
                                        </li>
                                        <li className="privacygap">
                                            <NavLink
                                            className="socila-images socila-images5"
                                                to={"https://vimeo.com/dailymines"}
                                                target="_blank"
                                            >
                                             <i class="fa-brands fa-vimeo social-icon"></i>
                                            </NavLink>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="footer-sec">
                                    <h6 className="footer-head">Useful Links</h6>
                                    <ul className="footer-lin">
                                        <li className="footerte">
                                            <NavLink
                                                className="footer-link-txt"
                                                to={"/landing"}
                                            >
                                                Home
                                            </NavLink>
                                        </li>
                                        <li className="footerte">
                                            <NavLink
                                                className="footer-link-txt"
                                                to={"/aboutus"}
                                            >
                                                About Us
                                            </NavLink>
                                        </li>
                                        {/* <li className="footerte">
                                            <NavLink
                                                className="footer-link-txt"
                                                to="../files/terms.pdf"
                                    target="_blank"
                                            >
                                                Terms & Conditions
                                            </NavLink>
                                        </li> */}
                                        <li className="footerte">
                                            <NavLink
                                                className="footer-link-txt"
                                                to="../files/privacy.pdf"
                                                target="_blank"
                                            >
                                                Privacy Policy
                                            </NavLink>
                                        </li>

                                        <li className="footerte">
                                            <NavLink
                                                className="footer-link-txt"
                                                to="../files/risk.pdf"
                                                target="_blank"
                                            >
                                                Risk Management
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="footer-sec">
                                    <h6 className="footer-head">Contact info</h6>
                                    <ul className="footer-lin">

                                        <li className="footerte">
                                            <ul className="address-ul">
                                                <li> <img
                                                    className="location-im"
                                                    src="../images/footer/tai-icon.png"
                                                    alt="Chakravuyha-Logo"
                                                /></li>
                                                <li className="location-text">Thailand, 999/9 Rama I Rd, Pathum Wan, Bangkok 10330, Thailand</li>
                                            </ul>
                                        </li>

                                        <li className="footerte">
                                            <ul className="address-ul">
                                                <li> <img
                                                    className="location-im"
                                                    src="../images/footer/uk-icon.png"
                                                    alt="Chakravuyha-Logo"
                                                /></li>
                                                <li className="location-text">7 Bell Yard, London, England, WC2A 2JR</li>
                                            </ul>
                                        </li>
                                        <li className="footerte">
                                            <ul className="address-ul">
                                                <li className="loaction-new1"> <img
                                                    className="location-im1"
                                                    src="../images/footer/mail.png"
                                                    alt="Chakravuyha-Logo"
                                                /></li>
                                                <li className="location-text">support@mydmfarms.com </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <p className="rightsres">
                                    Copyright © 2023 MyDMFarms. All Rights Reserved
                                </p>
                            </div>


                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default Footer;

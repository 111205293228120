import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Layout from './Pages/Layout/Layout';
import Landing from './Pages/Landing/Landing';
import About from './Pages/About/About';
import Ourteam from './Pages/Ourteam/Ourteam';
import Contactus from './Pages/Contactus/Contactus';



function App() {
  return (
    <>
    <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Landing />} />
          <Route path="/landing" element={<Landing />} />
          <Route path="/aboutus" element={<About />} />
          <Route path='/ourteam' element ={<Ourteam />} />
          <Route path='/contactus' element = {<Contactus />} />
      
        </Route>
      </Routes>
      </>
  );
}

export default App;


import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

const Ourteam = () => {
    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);
    return (
        <>
            <div className='ourteam-main'>
                <div className='ourteam-content'>
                    <div className='inside-data'>
                        <h3 className='ourteam-title'>OUR TEAM</h3>
                        <p className='ourteam-subtitle'><NavLink className="navlinksdata" to={"/landing"}>HOME </NavLink>/ My DM TEAM</p>
                    </div>

                </div>
                <div className='meet-dailymines-team'>
                    <div className='container'>
                        <div className='meet-dailymines-content'>
                            <p className='meet-dailymines-title'>Meet the <span>MyDMFarms Team</span></p>
                            <img className='line-images' src="../images/landing/line-image.png" alt='no-data' />

                            <p className='welcomeleft-para'>At MyDMFarms, our mission is to empower individuals to explore the exciting world of crypto mining. We believe that knowledge is the key to success in this ever-evolving industry. Our team is dedicated to providing you with the most comprehensive and up-to-date educational resources, including tutorials, guides, videos, and articles that cover everything from the basics of mining to advanced techniques and strategies. Get to know the passionate and experienced individuals who make it all possible.</p>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-3">
                                <div className="card meet-dailymines-list" >
                                <img src="../images/ourteam/curve-image.png" className="card-img-top curve-image" alt="..." />

                                    <div className='meet-dailymines-top'>
                                        <img src="../images/ourteam/profile-1.png" className="card-img-top dailyimage-inside" alt="..." />
                                    </div>
                                    <div className="card-body dailymines-body">
                                        <h5 className="card-title dailymines-title">Wesley Remington</h5>
                                        <h5 className="card-title dailymines-subtitle">Founder & CEO</h5>
                                        <p className="card-text dailymines-text">
                                        Wesley Remington is a Belarusian fintech professional and blockchain expert. He founded MyDMFarms in 2020 and has grown it into one of the most successful and dynamic businesses in the sector. Wesley found amazing value in crypto mining farms using renewable energy and has ever since been on a mission to democratise cryptocurrency mining.                                         </p>

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="card meet-dailymines-list" >
                                <img src="../images/ourteam/curve-image.png" className="card-img-top curve-image" alt="..." />

                                    <div className='meet-dailymines-top'>
                                        <img src="../images/ourteam/profile-2.png" className="card-img-top dailyimage-inside" alt="..." />
                                    </div>
                                    <div className="card-body dailymines-body">
                                        <h5 className="card-title dailymines-title">Emmett Walker</h5>
                                        <h5 className="card-title dailymines-subtitle">CTO</h5>
                                        <p className="card-text dailymines-text">Mr Emmett Walker is responsible for innovation, R&D, and aligning tech initiatives with business goals. He leads in implementing new technologies, managing IT infrastructure, and ensuring competitiveness. </p>

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="card meet-dailymines-list" >
                                <img src="../images/ourteam/curve-image.png" className="card-img-top curve-image" alt="..." />

                                    <div className='meet-dailymines-top'>
                                        <img src="../images/ourteam/profile-3.png" className="card-img-top dailyimage-inside" alt="..." />
                                    </div>
                                    <div className="card-body dailymines-body">
                                        <h5 className="card-title dailymines-title">Willow Roberts</h5>
                                        <h5 className="card-title dailymines-subtitle">CFO</h5>
                                        <p className="card-text dailymines-text">
                                        Willow Roberts oversees financial planning, record-keeping, and reporting, as well as analysing data to guide the organization's financial decisions. She often work closely with other top executives to develop strategies for long-term financial growth, manage risks, optimize capital structure, and ensure the company's financial health.                                          </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="card meet-dailymines-list" >
                                <img src="../images/ourteam/curve-image.png" className="card-img-top curve-image" alt="..." />

                                    <div className='meet-dailymines-top'>
                                        <img src="../images/ourteam/profile-4.png" className="card-img-top dailyimage-inside" alt="..." />
                                    </div>
                                    <div className="card-body dailymines-body">
                                        <h5 className="card-title dailymines-title">Dean Thomas </h5>
                                        <h5 className="card-title dailymines-subtitle">CMO</h5>
                                        <p className="card-text dailymines-text">Dean Thomas is responsible for developing and executing marketing strategies to drive brand awareness and customer acquisition. Their duties include market research, campaign planning, team leadership, and analysing metrics to optimise performance. He plays a vital role in aligning marketing efforts with business objectives. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="our-contributing">
                    <div className="container">
                    <h3 className="contribution-title">
                    Our Contributing Experts
                    </h3>
                    <p className="contribution-para">Beyond our core team, <span>MyDMFarms</span> collaborates with a network of industry experts, miners, and blockchain enthusiasts. These experts contribute their knowledge and experience to ensure that our educational resources are of the highest quality and reflect the latest developments in the field. </p>
                    <p className="contribution-para">At <span>MyDMFarms</span> , we're committed to your success as a crypto miner. Whether you're just starting or looking to take your mining operations to the next level, our team is here to guide you every step of the way. We're passionate about making crypto mining accessible and rewarding for all, and we're excited to be a part of your mining journey. Get started with us today and unlock the potential of this exciting industry!</p>

                    </div>
                </div>


            </div>
        </>
    )
}
export default Ourteam;
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';

const Contactus = () => {
  const [activeTab, setActiveTab] = useState('v-pills-home-tab'); // Set the default active tab

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const handleEmailClick = () => {
    window.location.href = 'mailto:support@mydmfarms.com';
  };
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <>
      <div className='contactus-main'>
        <div className='contactus-content'>
          <div className='inside-data'>
            <h3 className='contactus-title'>CONTACT US</h3>
            <p className='contactus-subtitle'><NavLink className="navlinksdata" to={"/landing"}>HOME </NavLink>/ CONTACT US</p>
          </div>

        </div>
        <div className='contactus-team'>
          <div className='container'>
            <div className='meet-dailymines-content'>
              <p className='meet-dailymines-title'>LET’S CONNECT!</p>
              <img className='line-images' src="../images/landing/line-image.png" alt='no-data' />


              <form className="contact-forms">
                <div className="row">
                  <div className="form-group col-md-6">
                    <label htmlFor="firstname" className="name-lables">First Name*</label>
                    <input
                      type="text"
                      id="firstname"
                      name="Firstname"
                      className="form-control text-fiealds"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="lastname" className="name-lables">Last Name*</label>
                    <input
                      type="text"
                      id="lastname"
                      name="Lastname"
                      className="form-control text-fiealds"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label htmlFor="email" className="name-lables">Email*</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="form-control text-fiealds"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="industry" className="name-lables">Company Name*</label>
                    <input
                      type="text"
                      id="industry"
                      name="Industry"
                      className="form-control text-fiealds text-fiealds1"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label htmlFor="country" className="name-lables">Country</label>
                    <select
                      id="country"
                      className="form-select text-fiealds"
                    >
                      <option value="india">India</option>
                      <option value="india1">India 1</option>
                    </select>
                  </div>

                  <div className="form-group col-md-6">
                    <label htmlFor="phone" className="name-lables">Mobile Number</label>
                    <input
                      type="tel"
                      id="phone"
                      name="Phone"
                      className="form-control text-fiealds text-fiealds1"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="message" className="name-lables">Description*</label>
                  <textarea
                    className="form-control"
                    id="message"
                    rows={3}
                    name="Message"
                  />
                </div>
                <div className="btndata-contact">
                  <button
                    type="submit"
                    className="btn btn-primary contactus-btn"
                  >
                    Contact Us
                  </button>
                </div>
              </form>




            </div>

          </div>

        </div>
        <div className='container'>
          <div className='contact-info'>
            <h3 className='contactinfo-title'>Conact Information</h3>


            <div className="d-flex align-items-start infodata-contact">
              <div className='left-info'>
                <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                  <p className='getstart-contact'>Get in touch with MyDMFarms team now and get started</p>
                  <button
                    className={`nav-link leftinfo-address ${activeTab === 'v-pills-home-tab' ? 'active' : ''}`}
                    id="v-pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-home"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-home"
                    aria-selected={activeTab === 'v-pills-home-tab'}
                    onClick={() => handleTabClick('v-pills-home-tab')}
                  >
                    <div className="d-flex address-media align-items-center">
                      <div className="flex-shrink-0">
                        <img className='loaction-images' src="../images/ourteam/loaction-image-1.png" alt='no-data' />
                      </div>
                      <div className="flex-grow-1 inside-bodys ms-3">
                        DM Thailand, 999/9 Rama I Rd, Pathum Wan, Bangkok 10330, Thailand
                      </div>
                      {activeTab === 'v-pills-home-tab' && <img className='tick-images' src="../images/ourteam/tick-image.png" alt='no-data' />}
                    </div>
                  </button>

                  <button
                    className={`nav-link leftinfo-address ${activeTab === 'v-pills-profile-tab' ? 'active' : ''}`}
                    id="v-pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-profile"
                    aria-selected={activeTab === 'v-pills-profile-tab'}
                    onClick={() => handleTabClick('v-pills-profile-tab')}
                  >
                    <div className="d-flex address-media align-items-center">
                      <div className="flex-shrink-0">
                        <img className='loaction-images' src="../images/ourteam/loaction-image-2.png" alt='no-data' />
                      </div>
                      <div className="flex-grow-1 inside-bodys ms-3">
                        7 Bell Yard, London, England,WC2A 2JR
                      </div>
                      {activeTab === 'v-pills-profile-tab' && <img className='tick-images' src="../images/ourteam/tick-image.png" alt='no-data' />}
                    </div>
                  </button>

                  <div className='leftinfo-address'>

                    <NavLink className="d-flex address-media align-items-center" onClick={handleEmailClick}>

                      <div className="flex-shrink-0">

                        <img className='loaction-images' src="../images/ourteam/email-image.png" alt='no-data' />

                      </div>

                      <div className="flex-grow-1 inside-bodys ms-3">

                      support@mydmfarms.com

                      </div>

                    </NavLink>

                  </div>
                </div>
              </div>
              <div className='right-info'>
                <div className="tab-content" id="v-pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="v-pills-home"
                    role="tabpanel"
                    aria-labelledby="v-pills-home-tab"
                  >
                    <div className='map-inside-data'>
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3875.543888225443!2d100.53576357522803!3d13.746041897426572!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29ecfd38e4023%3A0xeb8efc10247b487e!2zOTk5Lzkg4Lie4Lij4Liw4Lij4Liy4LihIDEgUmFtYSAxIFJvYWQgTHVtcGhpbmksIEtoZXQgUGF0aHVtIFdhbiwgS3J1bmcgVGhlc CBNYWhhIE5ha2hvbiAxMDMzMCwgVGhhaWxhbmQ!5e0!3m2!1sen!2sin!4v1699425893832!5m2!1sen!2sin"
                        className='map-data'
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        title="Google Maps - Location Description"
                      />
                    </div>

                  </div>
                  <div
                    className="tab-pane fade"
                    id="v-pills-profile"
                    role="tabpanel"
                    aria-labelledby="v-pills-profile-tab"
                  >
                    <div className='map-inside-data'>
                   
                      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.940002588728!2d-0.11436112344316136!3d51.514316710198166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487604b4a8219937%3A0xb3ae81fe06d94999!2s7%20Bell%20Yard%2C%20London%20WC2A%202JR%2C%20UK!5e0!3m2!1sen!2sin!4v1700209477945!5m2!1sen!2sin"  className='map-data'
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>


                  </div>

                </div>

              </div>
            </div>


          </div>

        </div>
      </div>
    </>
  )
}

export default Contactus;



